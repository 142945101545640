export const menuItems = [
    {
        id: 1,
        label: "Manage",
        isTitle: true,
        role: [0,1,2,3]
    },
    {
        id: 2,
        label: "Dashboard",
        icon: "ri-dashboard-line",
        link: '/home',
        role: [0,1,2,3]
    },
    {
        id: 3,
        label: "Profile",
        icon: "ri-account-circle-line",
        link: '/me',
        role: [0,1,2,3]
    },
    {
        id: 10,
        label: "Assistants",
        icon: "ri-admin-line",
        link: '/assistants',
        role: [0]
    },
    {
        id: 17,
        label: "Students",
        icon: "ri-group-line",
        link: '/students/search',
        role: [0,2,3]
    },
    {
        id: 19,
        label: "Balance",
        icon: "ri-money-dollar-box-line",
        link: '/redeem-code',
        role: [1]
    },
    {
        id: 4,
        label: "Attended",
        icon: "ri-calendar-check-fill",
        link: '/myattendance',
        role: [1]
    },
    {
        id: 6,
        label: "Work center",
        isTitle: true,
        role: [0]
    },
    {
        id: 7,
        label: "Centers",
        icon: "ri-home-2-line",
        link: '/centers',
        role: [0]
    },
    {
        id: 8,
        label: "Stages",
        icon: "ri-calendar-todo-fill",
        link: '/years',
        role: [0]
    },
    {
        id: 37,
        label: "Reports",
        icon: "ri-file-chart-fill",
        link: '/reports',
        role: [0]
    },
    {
        id: 22,
        label: "Announcements",
        icon: "ri-notification-line",
        link: '/announcements',
        role: [0,1]
    },
    {
        id: 23,
        label: "Landing Posts",
        icon: "ri-slideshow-2-line",
        link: '/posts/posts',
        role: [0]
    },
    {
        id: 14,
        label: "Lectures",
        icon: "ri-book-line",
        link: '/chapters',
        role: [0,2,3]
    },
    {
        id: 31,
        label: "Q & A section",
        icon: "ri-article-line",
        link: '/quizes',
        role: [0]
    },
    {
        id: 14,
        label: "Lectures",
        icon: "ri-briefcase-line",
        link: '/students/lectures',
        role: [1]
    },
    {
        id: 18,
        label: "Videos",
        icon: "ri-video-fill",
        link: '/videos',
        role: [0]
    },
    {
        id: 21,
        label: "Fantasy",
        icon: "ri-award-fill",
        link: '/my-fantasy',
        role: [1]
    },
    {
        id: 20,
        label: "Materials",
        icon: "ri-file-cloud-line",
        link: '/materials',
        role: [0]
    },
    {
        id: 30,
        label: "Scores",
        icon: "ri-star-line",
        link: '/students/scores',
        role: [1]
    },
    {
        id: 16,
        label: "Balance codes",
        icon: "ri-refund-2-line",
        link: '/balance-codes',
        role: [0,3]
    },
    {
        id: 11,
        label: "Attendance",
        isTitle: true,
        role: [0,2,3]
    },
    {
        id: 12,
        label: "Sessions",
        icon: " ri-calendar-event-line",
        link: '/sessions',
        role: [0,2,3]
    },
    
];

